import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { useQuery } from "@tanstack/react-query"
import { DateTime } from "luxon"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

export function useAutoQuery(queryFn, ...args) {
    return useQuery({
        queryKey: getQueryKey(queryFn, args),
        queryFn: () => queryFn(...args),
    })
}

export function invalidateAutoQuery(queryClient, queryFn, ...args) {
    let queryKey = getQueryKey(queryFn, args)
    queryClient.invalidateQueries(queryKey)
}

export function getQueryKey(queryFn, args) {
    return [queryFn.name, ...args]
}

export function epoch() {
    return DateTime.fromMillis(0)
}

export function roundedNow() {
    return DateTime.local().endOf("hour")
}

export function formatTrackDuration(durationMs) {
    return DateTime.fromMillis(durationMs).toFormat("m:ss")
}

export function formatListenDuration(milliseconds) {
    const oneSecond = 1000
    const oneMinute = 60000
    const oneHour = 3600000
    const oneDay = 86400000

    const days = Math.floor(milliseconds / oneDay)
    const hours = Math.floor((milliseconds % oneDay) / oneHour)
    const minutes = Math.floor((milliseconds % oneHour) / oneMinute)
    const seconds = Math.floor((milliseconds % oneSecond) / oneSecond)

    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""}`
    } else if (hours > 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`
    } else if (minutes > 0) {
        return `${minutes} min${minutes > 1 ? "s" : ""}`
    } else {
        return `${seconds} sec${seconds > 1 ? "s" : ""}`
    }
}

export function formatCount(count) {
    if (count >= 1000) {
        return (count / 1000).toPrecision(3) + "k"
    } else {
        return count
    }
}

export function formatDateHumanReadable(date) {
    return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
    })
}

export function formatReleaseDate(releaseDate) {
    const {year, month, day} = releaseDate

    if (year && month && day) {
        let dateTime = DateTime.fromObject({year, month, day})
        return dateTime.toFormat("MMMM d, yyyy")
    } else if (year && month) {
        let dateTime = DateTime.fromObject({year, month})
        return dateTime.toFormat("MMMM yyyy")
    } else if (year) {
        let dateTime = DateTime.fromObject({year})
        return dateTime.toFormat("yyyy")
    } else {
        return null
    }
}

export function getArtistName(artist) {
    const aliasByLocale = {}

    artist.aliases
        .filter(alias => alias.primaryForLocale)
        .forEach(alias => aliasByLocale[alias.locale] = alias.name)

    for (const locale of navigator.languages) {
        const alias = aliasByLocale[locale]
        if (alias != null) {
            return alias
        }
    }

    return artist.name
}

export async function sleep(duration) {
    await new Promise(resolve => setTimeout(resolve, duration.as("milliseconds")))
}

