import { formatCount, formatListenDuration, getArtistName } from "../utils"
import { Link } from "react-router-dom"
import { BarChart3, ClockIcon } from "lucide-react"
import React from "react"

function Pill({children}) {
    return (
        <div className="flex flex-row gap-2 items-center bg-gray-200 rounded-full px-3 py-1 w-fit text-xs">
            {children}
        </div>
    )
}

export function ArtistCard({group}) {
    const artist = group.artist
    const name = getArtistName(artist)

    return (
        <Link to={`/artist/${artist.id}`} className="flex flex-col">
            <div className="rounded-t-md border-t border-x">
                <img src={artist.images[0]?.url} alt={name} className="rounded-t-md w-full object-cover aspect-square"/>
            </div>
            <div className="flex flex-col gap-1 border rounded-b-md p-2">
                <span className="text-md font-semibold text-nowrap truncate">{name}</span>
                <Pill>
                    <BarChart3 className="w-3 h-3"/>
                    <span>{formatCount(group.count)} plays</span>
                </Pill>
                <Pill>
                    <ClockIcon className="w-3 h-3"/>
                    <span>{formatListenDuration(group.durationMs)}</span>
                </Pill>
            </div>
        </Link>
    )
}