import React from "react"
import { getArtistsGraph, getTopArtists } from "../ApiClient"
import { useAutoQuery } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { ArtistCard } from "./ArtistCard"
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./ui/chart"
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts"
import { DateTime } from "luxon"

function Graph({start, end}) {
    const {data: groups, isPending} = useAutoQuery(getArtistsGraph, start, end)

    if (isPending) {
        return
    }

    let times = {}
    let lines = new Set()

    for (let group of groups) {
        let time = times[group.partition] || {month: group.partition}
        let name = group.artist?.name || "Other"
        time[name] = group.count
        times[group.partition] = time
        lines.add(name)
    }

    times = Object.values(times).sort((a, b) => a.month.localeCompare(b.month))
    lines = [...lines].sort((a, b) => {
        if (a === "Other") {
            return 1
        } else if (b === "Other") {
            return -1
        } else {
            return -a.localeCompare(b)
        }
    })

    let colorIndex = 1
    let areas = lines.map(name => {
        let color
        if (name === "Other") {
            color = "chart-other"
        } else {
            color = `chart-${colorIndex}`
            colorIndex++
        }

        return (
            <Area
                dataKey={name}
                type="monotone"
                fill={`hsl(var(--${color}))`}
                fillOpacity={0.4} dddddd
                stroke={`hsl(var(--${color}))`}
                stackId="a"
                isAnimationActive={false}
                key={name}
            />
        )
    })

    return (
        <ChartContainer config={{}}>
            <AreaChart
                accessibilityLayer
                data={times}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false}/>
                <XAxis
                    dataKey="month"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={value => DateTime.fromFormat(value, "yyyy-MM-dd HH:mm:ss.S").toFormat("MMM d")}
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dot"/>}
                />
                {areas}
            </AreaChart>
        </ChartContainer>
    )
}

export function TopArtists({tagId, start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopArtists, tagId, start, end)

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Artists</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center gap-4">
                <div className="w-full items-center justify-center">
                    <Graph start={start} end={end}/>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <ArtistCard group={group} index={index} key={group.artist.id}/>)}
                </div>
            </CardContent>
        </Card>
    );
}
