import React from "react"
import { Link, useParams } from "react-router-dom"
import { getArtist, getArtistGraph } from "../ApiClient"
import { TopReleaseGroups } from "../components/TopReleaseGroups"
import { ListenOnSpotifyButton } from "../components/ListenOnSpotifyButton"
import { ArtistRankCard } from "../components/ArtistRankCard"
import { ArtistListeningTimeCard } from "../components/ArtistListeningTimeCard"
import { ArtistTotalListensCard } from "../components/ArtistTotalListensCard"
import { ArtistTopRecordingCard } from "../components/ArtistTopRecordingCard"
import { epoch, getArtistName, roundedNow, useAutoQuery } from "../utils"
import { TopRecordings } from "../components/TopRecordings"
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts"
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../components/ui/chart"
import { DateTime } from "luxon"

function Header({artistId}) {
    const {data: artist, isPending} = useAutoQuery(getArtist, artistId)

    if (isPending) {
        return null
    }

    const name = getArtistName(artist)

    return (
        <div className="flex flex-row gap-6 items-start">
            <img
                src={artist.images[0]?.url}
                alt={name}
                className="rounded-full w-32 h-32 md:w-48 md:h-48 object-cover"
            />
            <div className="flex flex-col gap-4">
                <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>
                <div className="text-xl text-gray-600 dark:text-gray-300">
                    {artist.tags
                        .map(tag => <TagLink tag={tag}/>)
                        .reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)
                    }
                </div>
                {artist.spotifyUrl != null ? <ListenOnSpotifyButton url={artist.spotifyUrl}/> : null}
            </div>
        </div>
    )
}

function TagLink({tag}) {
    return (
        <Link to={`/genre/${tag.id}`} className="hover:text-purple-600">
            {tag.name}
        </Link>
    )
}

function Summary({artistId}) {
    return (
        <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
            <ArtistTotalListensCard artistId={artistId}/>
            <ArtistListeningTimeCard artistId={artistId}/>
            <ArtistTopRecordingCard artistId={artistId}/>
            <ArtistRankCard artistId={artistId}/>
        </div>
    )
}

function Graph({artistId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: groups, isPending} = useAutoQuery(getArtistGraph, artistId, start, end)

    if (isPending) {
        return
    }

    let times = {}
    let lines = new Set()

    for (let group of groups) {
        let time = times[group.partition] || {month: group.partition}
        let name = group.artist?.name || "Other"
        time[name] = group.count
        times[group.partition] = time
        lines.add(name)
    }

    times = Object.values(times).sort((a, b) => a.month.localeCompare(b.month))
    lines = [...lines].sort((a, b) => {
        if (a === "Other") {
            return 1
        } else if (b === "Other") {
            return -1
        } else {
            return -a.localeCompare(b)
        }
    })

    let colorIndex = 1
    let areas = lines.map(name => {
        let color
        if (name === "Other") {
            color = "chart-other"
        } else {
            color = `chart-${colorIndex}`
            colorIndex++
        }

        return (
            <Area
                dataKey={name}
                type="monotone"
                fill={`hsl(var(--${color}))`}
                fillOpacity={0.4} dddddd
                stroke={`hsl(var(--${color}))`}
                stackId="a"
                isAnimationActive={false}
                key={name}
            />
        )
    })

    console.log(lines)

    return (
        <ChartContainer config={{}} className="w-full h-[25rem]">
            <AreaChart
                accessibilityLayer
                data={times}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false}/>
                <XAxis
                    dataKey="month"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={value => DateTime.fromFormat(value, "yyyy-MM-dd HH:mm:ss").toFormat("MMM d yyyy")}
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dot"/>}
                />
                {areas}
            </AreaChart>
        </ChartContainer>
    )
}

export function ArtistPage() {
    const {artistId} = useParams()
    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header artistId={artistId}/>
            <Summary artistId={artistId}/>
            <Graph artistId={artistId}/>
            <div className="grid sm:grid-colds-1 md:grid-cols-2 gap-4 w-full">
                <TopReleaseGroups artistId={artistId} start={start} end={end}/>
                <TopRecordings artistId={artistId} start={start} end={end}/>
            </div>
        </div>
    );
}