import React from "react"
import { HeadphonesIcon, SearchIcon } from "lucide-react"
import { Input } from "./ui/input"
import { Link, useNavigate } from "react-router-dom"
import { currentUserStore } from "../stores/CurrentUserStore"
import { LoginButton } from "./LoginButton"

function Logo() {
    return (
        <Link to="/" className="flex items-center gap-2 text-lg font-semibold md:text-base">
            <HeadphonesIcon className="w-6 h-6 text-purple-600 dark:text-purple-400"/>
            <span className="text-purple-600 dark:text-purple-400">insights.fm</span>
        </Link>
    )
}

function NavElement({text, path}) {
    return (
        <Link to={path}
              className="text-gray-600 text-nowrap dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-colors">
            {text}
        </Link>
    )
}

function Nav() {
    return (
        <nav
            className="flex-col hidden gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
            <Logo/>
            <NavElement text="Dashboard" path="/dashboard"/>
            <NavElement text="Your Artists" path="/dashboard"/>
            <NavElement text="Your Genres" path="/dashboard"/>
        </nav>
    )
}

function UserInfo() {
    const currentUser = currentUserStore((state) => state.currentUser)

    return (
        <div className="flex items-center h-full aspect-square">
            {currentUser ? <UserIcon/> : <LoginButton/>}
        </div>
    )
}

function UserIcon() {
    const currentUser = currentUserStore((state) => state.currentUser)

    return (
        <Link
            to="/dashboard"
            className="flex items-center justify-center h-full rounded-full overflow-hidden bg-gray-700 hover:bg-gray-600 transition-colors"
        >
            <img
                src={currentUser.images[0].url}
                alt="User Avatar"
                className="h-full aspect-square object-cover"
            />
        </Link>
    )
}

function SearchBar() {
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        const query = e.target.elements[0].value
        if (query) {
            navigate(`/search?q=${encodeURIComponent(query)}`)
        }
    }

    return (
        <form
            className="flex flex-row items-center p-2 h-full rounded-lg bg-gray-100 text-gray-500 border-gray-200 border-2 w-full"
            onSubmit={handleSubmit}>
            <SearchIcon className="h-fit text-gray-500"/>
            <Input
                type="search"
                placeholder="Search artists, tracks, albums..."
                className="w-[250px] border-0 text-gray-800 placeholder-gray-500 text-sm"
            />
        </form>
    )
}

export function Header() {
    return (
        <header
            className="flex items-center w-full justify-between h-16 p-2 border-b bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 shadow-sm">
            <Nav/>
            <div className="flex flex-row items-center gap-2 h-full">
                <SearchBar/>
                <UserInfo/>
            </div>
        </header>
    );
}
