import { Link } from "react-router-dom"
import { BarChart3, ClockIcon } from "lucide-react"
import { formatCount, formatListenDuration } from "../utils"
import React from "react"

export function ReleaseGroupCard({group}) {
    const releaseGroup = group.releaseGroup
    let name = releaseGroup.name
    if (releaseGroup.comment) {
        name += " (" + releaseGroup.comment + ")"
    }

    return (
        <Link to={`/album/${group.releaseGroup.id}`} className="flex flex-col">
            <div className="rounded-t-md border-t border-x">
                <img src={releaseGroup.images[0]?.url} alt={name} className="rounded-t-md aspect-square"/>
            </div>
            <div className="flex flex-col gap-1 border rounded-b-md p-2">
                <span className="text-md font-semibold text-nowrap truncate">{name}</span>
                <span className="text-sm text-nowrap truncate">{releaseGroup.artistCredit.name}</span>
                <div className="flex flex-row gap-2 items-center bg-gray-200 rounded-full px-3 py-1 w-fit">
                    <BarChart3 className="w-3 h-3"/>
                    <span className="text-xs">{formatCount(group.count)} plays</span>
                </div>
                <div className="flex flex-row gap-2 items-center bg-gray-200 rounded-full px-3 py-1 w-fit">
                    <ClockIcon className="w-3 h-3"/>
                    <span className="text-xs">{formatListenDuration(group.durationMs)}</span>
                </div>
            </div>
        </Link>
    )
}